import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import _ from 'lodash';
import axios from 'axios';
// import axios from 'src/utils/axios';
import objectArray from 'src/utils/objectArray';
import firebase from 'src/utils/firebase';
import {socketClient}  from '../index';

const initialState = {
  isLoaded: false,
  tasksArray: [],
  listsArray: [],
  lists: {
    byId: {},
    allIds: []
  },
  tasks: {
    byId: {},
    allIds: []
  },
  members: {
    byId: {},
    allIds: []
  }
};

export const requestChangeStatus = createAsyncThunk('requestChangeStatus', async function ({id, destinationId, draggableId, destination}, {getState, dispatch}) {
  console.log('requestChangeStatus');
  const state = getState().projectsBoard;


  const task = state.tasks.byId[id];
  const {vin, technician} = task;
  console.log(getState().projectsBoard.lists.byId);
  switch(destinationId){
    case '1': {
      dispatch(moveTask(draggableId, destination.index, destination.droppableId));
      socketClient.emit('changeWorkOrderStatus', {invoiceId: id, status: 'Quote', idToken: await firebase.auth().currentUser.getIdToken()});
      break;
    }
    case '2': {
      dispatch(moveTask(draggableId, destination.index, destination.droppableId));
      socketClient.emit('changeWorkOrderStatus', {invoiceId: id, status: 'Approved', idToken: await firebase.auth().currentUser.getIdToken()});
      break;
    }
    case '3': {
      if(/^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/g.test(vin) && (technician !== '' && technician !== null)){
        socketClient.emit('jobFinalized', {id, idToken: await firebase.auth().currentUser.getIdToken()});
        dispatch(moveTask(draggableId, destination.index, destination.droppableId));
      }else{
        alert('Please assign a technician and enter a valid VIN');
      }
      break;
    }
    case '4': {
      await axios.post(`https://redline-autohaus-api.herokuapp.com/active/jobs/delivered`, {id, cash: 0, smsReview: 1, idToken: await firebase.auth().currentUser.getIdToken()});
      dispatch(moveTask(draggableId, destination.index, destination.droppableId));
      break;
    }
    default: {
      break;
    }
  }
});

export const deleteWorkOrder = createAsyncThunk('deleteWorkOrder', async function (id, {dispatch}) {
  console.log('deleteWorkOrder');
  console.log(id);
  await axios.get(`https://redline-autohaus-api.herokuapp.com/invoice/delete/${id}`);
  dispatch(requestGetAllData());
});


export const requestGetSearchResults = createAsyncThunk('requestGetSearchResults', async function ({search, type}) {
  console.log('requestGetSearchResults');
  console.log(type);
  await socketClient.emit('searchWorkOrder', { type, value: search, idToken: await firebase.auth().currentUser.getIdToken()});
});

export const getSearchResults = createAsyncThunk('getSearchResults', async (_, { getState, dispatch }) => {
  await socketClient.on('searchWorkOrder', async (searchResponse) => {
    console.log('searchResponse');
    const state = getState().projectsBoard;
    

    let newState = {
      lists: [],
      tasks: [...state.tasksArray],
      members: [
        // {
        //   id: '1',
        //   avatar: '/static/images/avatars/1.jpg',
        //   name: 'Maren Lipshutz'
        // }
      ]
    };
    console.log(newState);

    switch (searchResponse.type) {
      case 'Quote':
        newState.lists[0] = {
          id: '1',
          name: 'Quotes',
          color: '#FF1A43',
          taskIds: []
        };
        newState.lists[1] = state.listsArray[1];
        newState.lists[2] = state.listsArray[2];
        newState.lists[3] = state.listsArray[3];
        break;
      case 'Approved':
        newState.lists[1] = {
          id: '2',
          name: 'Approved',
          color: '#33C2FF',
          taskIds: []
        }
        newState.lists[0] = state.listsArray[0];
        newState.lists[2] = state.listsArray[2];
        newState.lists[3] = state.listsArray[3];
        break;
      case 'Invoice':
        newState.lists[2] = {
          id: '3',
          name: 'Unpaid',
          color: '#FBEC0A',
          taskIds: []
        }
        newState.lists[0] = state.listsArray[0];
        newState.lists[1] = state.listsArray[1];
        newState.lists[3] = state.listsArray[3];
        break;
      case 'Delivered':
        newState.lists[3] = {
          id: '4',
          name: 'Paid',
          color: '#57CA22',
          taskIds: []
        }
        newState.lists[0] = state.listsArray[0];
        newState.lists[2] = state.listsArray[2];
        newState.lists[1] = state.listsArray[1];
        break;
      default:
        break;
    }

    for (let i = 0; i < 10; i++) {
      const job = searchResponse.data[i];
      if(!job) break;
      let task = {
        id: job.id.toString(),
        attachments: 0,
        progress: 25,
        sub_items: 0,
        comments: 0,
        image: job.vehicle.photo ? job.vehicle.photo : null,
        vehicle: `${job.vehicle.year} ${job.vehicle.make} ${job.vehicle.model}`,
        vin: job.vehicle.vin,
        mileage: job.odometer,
        due_date: job.createdAt,
        technician: job.technician,
        customer: job.vehicle.customer,
        memberIds: [],
        name: job.vehicle.customer.name,
        preTax:  (job.final_amount_charged ? job.final_amount_charged : 0).toFixed(2),
        totalAmountDue: (job.final_amount_charged * 1.13).toFixed(2),
        invoiceItems: job.invoiceItems
      };

      switch (searchResponse.type) {
        case 'Quote':
          task.listId = '1';
          newState.lists[0].taskIds.push(task.id);
          break;
        case 'Approved':
          task.listId = '2';
          newState.lists[1].taskIds.push(task.id);
          break;
        case 'Invoice':
          task.listId = '3';
          newState.lists[2].taskIds.push(task.id);
          break;
        case 'Delivered':
          task.listId = '4';
          newState.lists[3].taskIds.push(task.id);
          break;
        default:
          break;
      }
      
      newState.tasks.push(task);

    }

    console.log(newState);

    dispatch({ type: 'projects_board/getWorkOrders', payload: newState });
  });
});

export const requestGetAllData = createAsyncThunk('requestGetAllData', async function () {
  console.log('requestGetAllData');
  await socketClient.emit('getAllData', { idToken: await firebase.auth().currentUser.getIdToken()});
});

export const getWorkOrders = createAsyncThunk(
  'getAllData',
  async function (_, { dispatch }) {
    console.log('getWorkOrders');
    await socketClient.on('getAllData', (response) => {
      const data = {
        lists: [
          {
            id: '1',
            name: 'Quotes',
            color: '#FF1A43',
            taskIds: []
          },
          {
            id: '2',
            name: 'Approved',
            color: '#33C2FF',
            taskIds: []
          },
          {
            id: '3',
            name: 'Unpaid',
            color: '#FBEC0A',
            taskIds: []
          },
          {
            id: '4',
            name: 'Paid',
            color: '#57CA22',
            taskIds: []
          }
        ],
        tasks: [
          
        ],
        members: [
          // {
          //   id: '1',
          //   avatar: '/static/images/avatars/1.jpg',
          //   name: 'Maren Lipshutz'
          // }
        ]
      };

      for (let i = 0; i < response.jobs.length; i++) {

        const job = response.jobs[i];
        console.log(job);
        const task = {
          id: job.id.toString(),
          attachments: 0,
          progress: 25,
          sub_items: 0,
          comments: 0,
          image: job.vehicle.photo ? job.vehicle.photo : null,
          vehicle: `${job.vehicle.year} ${job.vehicle.make} ${job.vehicle.model}`,
          vin: job.vehicle.vin,
          mileage: job.odometer,
          due_date: job.createdAt,
          listId: '2',
          memberIds: [],
          name: job.vehicle.customer.name,
          customer: job.vehicle.customer,
          technician: job.technician,
          preTax:  (job.final_amount_charged).toFixed(2),
          totalAmountDue:  (job.final_amount_charged*1.13).toFixed(2),
          invoiceItems: job.invoiceItems
        }

        data.lists[1].taskIds.push(job.id.toString());
        
        data.tasks.push(task);
      }

      for (let i = 0; i < response.invoices.length; i++) {

        const job = response.invoices[i];
        console.log(job);
        const task = {
          id: job.id.toString(),
          attachments: 0,
          progress: 25,
          sub_items: 0,
          comments: 0,
          image: job.vehicle.photo ? job.vehicle.photo : null,
          vehicle: `${job.vehicle.year} ${job.vehicle.make} ${job.vehicle.model}`,
          vin: job.vehicle.vin,
          mileage: job.odometer,
          due_date: job.createdAt,
          listId: '3',
          memberIds: [],
          name: job.vehicle.customer.name,
          customer: job.vehicle.customer,
          technician: job.technician,
          preTax:  (job.final_amount_charged).toFixed(2),
          totalAmountDue:  (job.final_amount_charged*1.13).toFixed(2),
          invoiceItems: job.invoiceItems
        }

        data.lists[2].taskIds.push(job.id.toString());
        
        data.tasks.push(task);
      }

      for (let i = 0; i < response.delivered.length; i++) {

        const job = response.delivered[i];
    
        const task = {
          id: job.id.toString(),
          attachments: 0,
          progress: 25,
          sub_items: 0,
          comments: 0,
          image: job.vehicle.photo ? job.vehicle.photo : null,
          vehicle: `${job.vehicle.year} ${job.vehicle.make} ${job.vehicle.model}`,
          vin: job.vehicle.vin,
          mileage: job.odometer,
          due_date: job.createdAt,
          listId: '4',
          memberIds: [],
          name: job.vehicle.customer.name,
          customer: job.vehicle.customer,
          technician: job.technician,
          preTax:  (job.final_amount_charged).toFixed(2),
          totalAmountDue:  (job.final_amount_charged*1.13).toFixed(2),
          invoiceItems: job.invoiceItems
        }

        data.lists[3].taskIds.push(job.id.toString());
        
        data.tasks.push(task);
      }

      for (let i = 0; i < response.stats.quotes.length; i++) {

        const job = response.stats.quotes[i];
    
        const task = {
          id: job.id.toString(),
          attachments: 0,
          progress: 25,
          sub_items: 0,
          comments: 0,
          image: job.vehicle.photo ? job.vehicle.photo : null,
          vehicle: `${job.vehicle.year} ${job.vehicle.make} ${job.vehicle.model}`,
          vin: job.vehicle.vin,
          mileage: job.odometer,
          due_date: job.createdAt,
          listId: '1',
          memberIds: [],
          name: job.vehicle.customer.name,
          customer: job.vehicle.customer,
          technician: job.technician,
          preTax:  (job.final_amount_charged ? job.final_amount_charged : 0).toFixed(2),
          totalAmountDue:  (job.final_amount_charged*1.13).toFixed(2),
          invoiceItems: job.invoiceItems
        }

        data.lists[0].taskIds.push(job.id.toString());
        
        data.tasks.push(task);
      }

      dispatch({ type: 'projects_board/getWorkOrders', payload: data });
      // dispatch(slice.actions.getWorkOrders({payload: data}));
    });
  }
);

const slice = createSlice({
  name: 'projects_board',
  initialState,
  reducers: {
    getWorkOrders(state, action) {
      console.log(state, action);

      // const { project } = action.payload;
      console.log(action.payload);
      state.tasksArray = action.payload.tasks;
      state.listsArray = action.payload.lists;
      state.lists.byId = objectArray(action.payload.lists);
      state.lists.allIds = Object.keys(state.lists.byId);
      state.tasks.byId = objectArray(action.payload.tasks);
      state.tasks.allIds = Object.keys(state.tasks.byId);
      state.members.byId = objectArray(action.payload.members);
      state.members.allIds = Object.keys(state.members.byId);
      state.isLoaded = true;

    },

    updateList(state, action) {
      const { list } = action.payload;

      state.lists.byId[list.id] = list;
    },

    moveTask(state, action) {
      const { taskId, position, listId } = action.payload;
      const sourceListId = state.tasks.byId[taskId].listId;
      console.log(sourceListId);
      if (sourceListId) {
        // Remove the task ID from the source list
        const sourceList = state.lists.byId[sourceListId];
        state.lists.byId[sourceListId] = {
          ...sourceList,
          taskIds: sourceList.taskIds.filter(id => id !== taskId),
        };
      }
    
      // Update the task's list ID
      state.tasks.byId[taskId] = {
        ...state.tasks.byId[taskId],
        listId,
      };
    
      // Add the task ID to the destination list
      const destinationList = state.lists.byId[listId];
      state.lists.byId[listId] = {
        ...destinationList,
        taskIds: [
          ...destinationList.taskIds.slice(0, position),
          taskId,
          ...destinationList.taskIds.slice(position),
        ],
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(requestGetAllData.pending, (state) => {
      state.requestAllDataStatus = 'Sending';
    });
    builder.addCase(requestGetAllData.fulfilled, (state) => {
      state.requestAllDataStatus = 'Sent successfully';
    });
    builder.addCase(requestGetAllData.rejected, (state) => {
      state.requestAllDataStatus = 'Send failed';
    });
  }
});

export const reducer = slice.reducer;

export const updateList = (listId, update) => async (dispatch) => {
  const response = await axios.post('/api/projects_board/list/update', {
    listId,
    update
  });

  dispatch(slice.actions.updateList(response.data));
};

export const moveTask = (taskId, position, listId) => async (dispatch) => {
  // await axios.post('/api/projects_board/tasks/move', {
  //   taskId,
  //   position,
  //   listId
  // });

  dispatch(
    slice.actions.moveTask({
      taskId,
      position,
      listId
    })
  );
};

export default slice;
