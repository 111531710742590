import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
import firebase from 'src/utils/firebase';
import {socketClient}  from '../index';
// import objectArray from 'src/utils/objectArray';

const initialState = {
  mails: {
    byId: {},
    allIds: []
  },
  inventoryList: [],
  tags: [],
  sidebarOpen: false,
  loading: true,
  rinLog: []
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    getTags(state, action) {
      const { tags } = action.payload;
      state.tags = tags;
    },
    getMails(state, action) {
      const { mails } = action.payload;

      state.mails.byId = {};
      state.mails.allIds = [];
      mails.forEach(mail => {
        state.mails.byId[mail.id] = mail;
        state.mails.allIds.push(mail.id); // Add to the end of the array
      });
      state.loading = false;
      
    },
    getMail(state, action) {
      const { mail } = action.payload;

      state.mails.byId[mail.id] = mail;

      if (!state.mails.allIds.includes(mail.id)) {
        state.mails.allIds.push(mail.id);
      }
    },
    getRinLog(state, action) {
      const { rinLogs } = action.payload;
      state.rinLog = rinLogs;
    },
    getInventory(state, action) {
      const { inventoryList } = action.payload;
      state.inventoryList = inventoryList;
    },
    setLoadingTrue(state) {
      state.loading = true;
    },
    openSidebar(state) {
      state.sidebarOpen = true;
    },
    closeSidebar(state) {
      state.sidebarOpen = false;
    }
  }
});

export const reducer = slice.reducer;

export const getTags = () => async (dispatch) => {
  const tags = [
    {id: 'inventory', type: 'category_tag', name: 'Inventory', newMails: 0},
    {id: 'financeleads', type: 'category_tag', name: 'Finance Leads', newMails: 0},
    {id: 'contracts', type: 'category_tag', name: 'Sales Contracts', newMails: 0},
    {id: 'rin', type: 'category_tag', name: 'RIN Log', newMails: 0}
  ];
  dispatch(slice.actions.getTags({tags}));
};

export const getMails = (params) => async (dispatch) => {
  dispatch(slice.actions.setLoadingTrue());
  if(params.categoryTag === 'inventory'){
    try{
      const invoicesResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/sales/vehicles/get');
      const listings = invoicesResponse.data.listings;
      let data = [];
      listings.forEach((listing) => {
        let tempData = {
          category: 'inventory',
          content: `<p>${listing.retail_price}</p><p>Stock Number: ${listing.vehicle.stockNumber}</p><a href="${listing.carfax}" target="_blank">View CARFAX</a>`,
          subject: `${listing.status}`,
          summary: `${listing.retail_price}`,
          listing,
          vehicle: listing.vehicle,
          date: moment(listing.createdAt).format('YYYY-MM-DD'),
          from: {name: listing.title, email: 'veer@example.com', avatar: listing && listing.vehicle_listings_photos[0] ? listing.vehicle_listings_photos[0].url : '/static/images/avatars/1.jpg', displayAvatar: 1},
          id: listing.id.toString(),
          opened: false,
          to: [{name: 'Michele Rodrigues', email: 'michele.r@example.com', avatar: '/static/images/avatars/1.jpg'}],
          tagIds: ['1']
        }
        data.push(tempData);
      });
      console.log(data);
      dispatch(slice.actions.getMails({mails: data}));
    }catch(error){
      console.error(error);
    }
  }else if(params.categoryTag === 'financeleads'){
    const financeAppRes = await axios.get('https://redline-autohaus-api.herokuapp.com/sales/finance-applications/get');
    const applications = financeAppRes.data;
    let data = [];
    applications.forEach((application) => {
      let tempData = {
        category: 'financeleads',
        content: `<p>${application.creditRating}</p><p>Vehicle: ${application.carType}</p>`,
        subject: `${application.creditRating}`,
        summary: `${application.carType}`,
        application,
        date: moment(application.createdAt).format('YYYY-MM-DD'),
        from: {name: `${application.firstName} ${application.lastName}`, email: `${application.carType}`, avatar: '/static/images/avatars/1.jpg', displayAvatar: 0},
        id: application.id.toString(),
        opened: false,
        to: [{name: 'Redline Auto Mall', email: 'info@redlineautohaus.ca', avatar: '/static/images/avatars/1.jpg'}],
        tagIds: ['1']
      }
      data.push(tempData);
    });
    dispatch(slice.actions.getMails({mails: data}));
    console.log('financeleads');
  }else if(params.categoryTag === 'contracts'){
    const billResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/bill-of-sale/get');
    const bills = billResponse.data;
    let data = [];
    bills.forEach((bill) => {
      let tempData = {
        category: 'contracts',
        content: `<p>${bill.sellingPrice}</p><p>Vehicle: ${bill.vehicleYear} ${bill.vehicleMake} ${bill.vehicleModel}</p>`,
        subject: `${bill.vehicleYear} ${bill.vehicleMake} ${bill.vehicleModel}`,
        summary: `${bill.sellingPrice} - ${bill.vinNumber}`,
        bill,
        date: moment(bill.createdAt).format('YYYY-MM-DD'),
        from: {name: `${bill.firstName} ${bill.lastName}`, email: `${bill.vehicleYear} ${bill.vehicleMake} ${bill.vehicleModel}`, avatar: '/static/images/avatars/1.jpg', displayAvatar: 0},
        id: bill.id.toString(),
        opened: false,
        to: [{name: 'Redline Auto Mall', email: 'info@redlineautomall.ca', avatar: '/static/images/avatars/1.jpg'}],
        tagIds: ['1']
      }
      data.push(tempData);
    });
    dispatch(slice.actions.getMails({mails: data}));
    console.log('contracts');
    console.log(data);
  }else if(params.categoryTag === 'rin'){
    const rinResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/rin-log/get');
    const rinLogs = rinResponse.data;
    let data = [];
    rinLogs.forEach((rinLog) => {
      let tempData = {
        category: 'rin',
        content: `<p>${rinLog.vin}</p><p>Vehicle: ${rinLog.year} ${rinLog.make} ${rinLog.model}</p>`,
        subject: `${rinLog.year} ${rinLog.make} ${rinLog.model}`,
        summary: `${rinLog.vin} - Purchased: ${rinLog.datePurchased} - Sold To: ${rinLog.soldToName}`,
        rinLog,
        date: moment(rinLog.dateSold ? rinLog.dateSold : rinLog.datePurchased).format('YYYY-MM-DD'),
        from: {name: `${rinLog.purchasedFromName}`, email: `${rinLog.vin}`, avatar: '/static/images/avatars/1.jpg', displayAvatar: 0},
        id: rinLog.id.toString(),
        opened: false,
        to: [{name: 'Redline Auto Mall', email: 'info@redlineautohaus.ca', avatar: '/static/images/avatars/1.jpg'}],
        tagIds: ['1']
      }
      data.push(tempData);
    });
    dispatch(slice.actions.getMails({mails: data}));
    console.log('financeleads');
  }

};

export const getMail = (mailboxCategory) => async (dispatch) => {
  const response = await axios.get('/api/mailbox/mail', {
    params: {
      mailboxCategory
    }
  });

  dispatch(slice.actions.getMail(response.data));
};

export const openSidebar = () => async (dispatch) => {
  dispatch(slice.actions.openSidebar());
};

export const closeSidebar = () => async (dispatch) => {
  dispatch(slice.actions.closeSidebar());
};

export const getRinLog = () => async (dispatch) => {
  const rinResponse = await axios.get('https://redline-autohaus-api.herokuapp.com/rin-log/get');
  const rinLogs = rinResponse.data;
  dispatch(slice.actions.getRinLog({rinLogs}));
};

export const addInventory = (payload) => async (dispatch) => {
  await axios.post('https://redline-autohaus-api.herokuapp.com/sales/vehicle/new', payload);
  dispatch(getMails({categoryTag: 'inventory'}));
};

export const updateInventory = (payload) => async (dispatch) => {
  await axios.post('https://redline-autohaus-api.herokuapp.com/sales/vehicle/new/edit', payload);
  dispatch(getMails({categoryTag: 'inventory'}));
};

export const createSalesContract = (payload) => async (dispatch) => {
  await axios.post('https://redline-autohaus-api.herokuapp.com/bill-of-sale/new', payload);
  dispatch(getMails({categoryTag: 'contracts'}));
}

export const updateSalesContract = (payload) => async (dispatch) => {
  await axios.post('https://redline-autohaus-api.herokuapp.com/bill-of-sale/new', payload);
  dispatch(getMails({categoryTag: 'contracts'}));
}

export const uploadPhoto = createAsyncThunk('uploadPhoto', async function (payload) {
  try{
    console.log(payload);
    const file = payload.file;
    const currentListingId = payload.currentListingId;
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`${file.name} ${moment().format('YYYYMMDDHHmmss')}`);
    await fileRef.put(file);
    const currentUrl = await fileRef.getDownloadURL();
    await socketClient.emit('uploadListingPhoto', { url: currentUrl, vehicle_id: currentListingId });
  }catch (e){
    console.log(e);
  }
});

export const getInventoryList = createAsyncThunk('getInventoryList', async function () {
  console.log('getInventoryList');
  await socketClient.emit('getInventory');
});

export const fetchGetInventory = createAsyncThunk(
  'fetchGetInventory',
  async function (_, { dispatch }) {
    await socketClient.on('getInventory', (data) => {
      console.log(data);
      dispatch(slice.actions.getInventory({inventoryList: data}));
    });
  });

export const deleteListingPhoto = createAsyncThunk('deleteListingPhoto', async function (payload) {
  await socketClient.emit('deleteListingPhoto', { photo_id: payload.photo_id, vehicle_id: payload.vehicle_id});
});

export const fetchdDeleteListingPhoto = createAsyncThunk(
  'fetchdDeleteListingPhoto',
  async function (_, { dispatch }) {
    await socketClient.on('deleteListingPhoto', () => {
      dispatch(getMails({categoryTag: 'inventory'}));
    });
  });

export default slice;
