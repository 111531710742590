import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firebase from 'src/utils/firebase';
import { socketClient } from '../index';


const initialState = {
  customer: {},
  vehicles: [],
  invoices: [],
  appointments: [],
  messagesData: [],
  messagesList: [],
  loading: false,
  messageLoading: false,
  listLoading: false,
};

export const requestAllMessages = createAsyncThunk('fetchAllMessages', async (_, { getState }) => {
  const state = getState();
  const idToken = await firebase.auth().currentUser.getIdToken();
  const startingId = state.someStateProp; // Adjust this to get the correct startingId from state
  socketClient.emit('getAllMessages', { idToken, startingId });
});

export const fetchAllMessages = createAsyncThunk('fetchAllMessages', async (_, { dispatch }) => {
  socketClient.on('getAllMessages', (data) => {
    dispatch(updateAllMessages(data));
  });
});

export const requestCustomerMessage = createAsyncThunk('requestCustomerMessage', async (customerId) => {
  const idToken = await firebase.auth().currentUser.getIdToken();
  socketClient.emit('getCustomerMessages', { idToken, customer_id: customerId });
});

export const fetchCustomerMessages = createAsyncThunk('fetchCustomerMessages', async (_, { dispatch }) => {
  socketClient.on('getCustomerMessages', (data) => {
    dispatch(updateCustomerMessages(data));
  });
});

export const requestSearchMessages = createAsyncThunk('requestSearchMessages', async (searchTerm) => {
  const idToken = await firebase.auth().currentUser.getIdToken();
  socketClient.emit('searchMessages', { idToken, searchTerm });
});

export const fetchSearchMessages = createAsyncThunk('fetchSearchMessages', async (_, { dispatch }) => {
  socketClient.on('searchMessages', (data) => {
    dispatch(updateMessagesList(data));
  });
});

export const fetchNewMessage = createAsyncThunk('fetchNewMessage', async (_, { dispatch, getState }) => {
  socketClient.on("newMessage", (data) => {
    const customerId = getState().sms.customer.id;
    if (data.customer.id === customerId) {
      dispatch(newSms(data));
      console.log(getState().sms)
    }
  });
});

export const requestSendMessage = createAsyncThunk('requestSendMessage', async (message, {getState}) => {
  const idToken = await firebase.auth().currentUser.getIdToken();
  const customer = getState().sms.customer;
  console.log(customer);
  const res = await axios.post(`https://redline-autohaus-api.herokuapp.com/message/send`, {customer, message});
  console.log(res);
  socketClient.emit('getCustomerMessages', { idToken, customer_id: customer.id });
});

const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    updateAllMessages(state, action) {
      const data = action.payload;
      state.customer = data.customer;
      state.messagesData = data.messages;
      state.messagesList = data.messagesList;
      state.vehicles = data.customer.vehicles;
      state.appointments = data.customer.vehicles.flatMap(vehicle => vehicle.appointments);
      state.invoices = data.customer.vehicles.flatMap(vehicle => vehicle.invoices);
      state.loading = true;
    },
    updateCustomerMessages(state, action) {
      const data = action.payload;
      state.customer = data.customer;
      state.messagesData = data.messages;
      state.vehicles = data.customer.vehicles;
      state.appointments = data.customer.vehicles.flatMap(vehicle => vehicle.appointments);
      state.invoices = data.customer.vehicles.flatMap(vehicle => vehicle.invoices);
      state.messageLoading = false;
    },
    newSms(state, action) {
      const data = action.payload;
      console.log(data);
      state.customer = data.messageListData.customer;
      state.messagesData = data.messageListData.messages;
      state.messagesList = data.messageListData.messagesList;
      state.vehicles = data.messageListData.customer.vehicles;
      state.appointments = data.messageListData.customer.vehicles.flatMap(vehicle => vehicle.appointments);
      state.invoices = data.messageListData.customer.vehicles.flatMap(vehicle => vehicle.invoices);
      state.messageLoading = false;
    },
    updateMessagesList(state, action) {
      state.messagesList = action.payload;
      state.listLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllMessages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllMessages.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchAllMessages.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchCustomerMessages.pending, (state) => {
      state.messageLoading = true;
    });
    builder.addCase(fetchCustomerMessages.fulfilled, (state) => {
      state.messageLoading = false;
    });
    builder.addCase(fetchCustomerMessages.rejected, (state) => {
      state.messageLoading = false;
    });
    builder.addCase(fetchSearchMessages.pending, (state) => {
      state.listLoading = true;
    });
    builder.addCase(fetchSearchMessages.fulfilled, (state) => {
      state.listLoading = false;
    });
    builder.addCase(fetchSearchMessages.rejected, (state) => {
      state.listLoading = false;
    });
  },
});

export const { updateAllMessages, updateCustomerMessages, updateMessagesList, newSms } = slice.actions;
export const reducer = slice.reducer;