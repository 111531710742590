import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firebase from 'src/utils/firebase';
import { socketClient } from '../index';

const initialState = {
  services: [],
  loading: false,
  error: null,
};

export const fetchServices = createAsyncThunk('services/fetchServices', async function () {
  await socketClient.emit('getServices', await firebase.auth().currentUser.getIdToken());
});

export const getServices = createAsyncThunk(
  'getServices',
  async function (_, { dispatch }) {
    await socketClient.on('getServices', (response) => {
      console.log(response);
      dispatch(slice.actions.setServices(response));
    });
});

export const createService = createAsyncThunk('services/createService', async function (payload) {
  await socketClient.emit('createService', { ...payload, idToken: await firebase.auth().currentUser.getIdToken() });
});

export const editService = createAsyncThunk('services/editService', async function (payload) {
  await socketClient.emit('editService', { ...payload, idToken: await firebase.auth().currentUser.getIdToken() });
});

export const deleteService = createAsyncThunk('services/deleteService', async function (id) {
  await socketClient.emit('deleteService', { id, idToken: await firebase.auth().currentUser.getIdToken() });
});

const slice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServices(state, action) {
      state.services = action.payload;
      state.loading = false;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchServices.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setServices, setLoading, setError } = slice.actions;
export const reducer = slice.reducer;