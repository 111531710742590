import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Users = Loader(lazy(() => import('src/content/management/Users')));
const SingleUser = Loader(
  lazy(() => import('src/content/management/Users/single'))
);
const Projects = Loader(lazy(() => import('src/content/management/Projects')));
const Invoices = Loader(lazy(() => import('src/content/management/Invoices')));
const SingleInvoice = Loader(
  lazy(() => import('src/content/management/Invoices/single'))
);
const Products = Loader(lazy(() => import('src/content/management/Products')));
const CreateProduct = Loader(
  lazy(() => import('src/content/management/Products/create'))
);
const CreateWorkOrder = Loader(
  lazy(() => import('src/content/management/WorkOrders/create'))
);
const SingleProduct = Loader(
  lazy(() => import('src/content/management/Products/single'))
);
const Shop = Loader(lazy(() => import('src/content/management/Products/shop')));
const Payroll = Loader(lazy(() => import('src/content/management/Payroll')));
const Technicians = Loader(lazy(() => import('src/content/management/Employees/Technicians')));
const ServiceAdvisors = Loader(lazy(() => import('src/content/management/Employees/ServiceAdvisors')));
const Settings = Loader(lazy(() => import('src/content/management/Settings')));
const Services = Loader(lazy(() => import('src/content/management/Services')));

const managementRoutes = [
  {
    path: '/',
    element: <Navigate to="users" replace />
  },
  {
    path:'/payroll',
    element: <Payroll />
  },
  {
    path:'/advisors',
    element: <ServiceAdvisors />
  },
  {
    path:'/technicians',
    element: <Technicians />
  },
  {
    path:'/settings',
    element: <Settings />
  },
  {
    path:'/services',
    element: <Services />
  },
  {
    path: 'users',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Users />
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="1" replace />
          },
          {
            path: ':userId',
            element: <SingleUser />
          }
        ]
      }
    ]
  },
  {
    path: 'projects',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Projects />
      }
    ]
  },
  {
    path: 'commerce',
    children: [
      {
        path: '/',
        element: <Navigate to="shop" replace />
      },
      {
        path: 'shop',
        element: <Shop />
      },
      {
        path: 'products',
        children: [
          {
            path: '/',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Products />
          },
          {
            path: 'create',
            element: <CreateProduct />
          },
          {
            path: 'single',
            children: [
              {
                path: '/',
                element: <Navigate to="1" replace />
              },
              {
                path: ':productId',
                element: <SingleProduct />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'invoices',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Invoices />
      },
      {
        path: 'edit',
        children: [
          {
            path: ':invoiceId',
            element: <CreateWorkOrder />
          }
        ]
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="1" replace />
          },
          {
            path: ':invoiceId',
            element: <SingleInvoice />
          }
        ]
      }
    ]
  }
];

export default managementRoutes;
