import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firebase from 'src/utils/firebase';
import {socketClient}  from '../index';


const initialState = {
  currentInvoice: {},
  services: {},
  warranties: {},
  technicians: [],
};

export const changeTechnician = createAsyncThunk('changeTechnician', async function (payload) {
  await socketClient.emit('changeTechnician', { invoiceId: payload.invoiceId, technician: payload.technician, idToken: await firebase.auth().currentUser.getIdToken() });
});

export const vinChanged = createAsyncThunk('vinChanged', async function (payload) {
  await socketClient.emit('vinChanged', { vin: payload.vin, id: payload.id, idToken: await firebase.auth().currentUser.getIdToken() });
});

export const plateChanged = createAsyncThunk('plateChanged', async function (payload) {
  console.log('plateChanged');
  console.log(payload);
  await socketClient.emit('plateChanged', { plate: payload.plate, id: payload.id, idToken: await firebase.auth().currentUser.getIdToken() });
});

export const odometerChanged = createAsyncThunk('odometerChanged', async function (payload) {
  await socketClient.emit('odometerChanged', { odometer: payload.odometer, id: payload.id, idToken: await firebase.auth().currentUser.getIdToken() });
});

export const odometerChangedAtCompletion = createAsyncThunk('odometerChangedAtCompletion', async function (payload) {
  await socketClient.emit('odometerAtCompletionChanged', { odometer: payload.odometer, id: payload.id, idToken: await firebase.auth().currentUser.getIdToken() });
});

export const notesChanged = createAsyncThunk('notesChanged', async function (payload) {
  await socketClient.emit('notesChanged', { notes: payload.notes, id: payload.id, idToken: await firebase.auth().currentUser.getIdToken() });
});

export const partNumberChanged = createAsyncThunk('partNumberChanged', async function (payload) {
  await socketClient.emit('partNumberChanged', { partNumber: payload.partNumber, id: payload.id, idToken: await firebase.auth().currentUser.getIdToken() });
});

export const getCurrentInvoice = createAsyncThunk('getCurrentInvoice', async function (invoiceId) {
  await socketClient.emit('getInvoice', {idToken: await firebase.auth().currentUser.getIdToken(), invoiceId});
});

export const createInvoiceItem = createAsyncThunk('createInvoiceItem', async function (invoiceId) {
  await socketClient.emit('createInvoiceItem', {id: invoiceId, idToken: await firebase.auth().currentUser.getIdToken()});
});

export const deleteInvoiceItem = createAsyncThunk('deleteInvoiceItem', async function (invoiceId) {
  await socketClient.emit('deleteInvoiceItem', {id: invoiceId, idToken: await firebase.auth().currentUser.getIdToken()});
});

export const descriptionChanged = createAsyncThunk('descriptionChanged', async function (payload) {
  await socketClient.emit('descriptionChanged', {description: payload.description, id: payload.id, idToken: await firebase.auth().currentUser.getIdToken()});
});

export const getServices = createAsyncThunk('getServices', async function () {
  await socketClient.emit('getServices', await firebase.auth().currentUser.getIdToken());
});

export const changePricePerUnit = createAsyncThunk('changePricePerUnit', async function (payload) {
  await socketClient.emit('pricePerUnitChanged', {pricePerUnit: payload.pricePerUnit, id: payload.id, idToken: await firebase.auth().currentUser.getIdToken()});
});

export const changeQuantity = createAsyncThunk('changeQuantity', async function (payload) {
  await socketClient.emit('quantityChanged', {quantity: payload.quantity, id: payload.id, idToken: await firebase.auth().currentUser.getIdToken()});
});

export const getWarranties = createAsyncThunk('getWarranties', async function () {
  await socketClient.emit('getWarranties', await firebase.auth().currentUser.getIdToken());
});

export const changeServiceType = createAsyncThunk('changeServiceType', async function (payload) {
  await socketClient.emit('serviceChanged', {idToken: await firebase.auth().currentUser.getIdToken(), id: payload.id, service: payload.serviceType});
});

export const updateFees = createAsyncThunk('updateFees', async function (payload) {
  await socketClient.emit('updateFees', {idToken: await firebase.auth().currentUser.getIdToken(), id: payload.id, fees: payload.fees});
});

export const changeCostPerUnit = createAsyncThunk('changeCostPerUnit', async function (payload) {
  console.log('changeCostPerUnit');
  await socketClient.emit('costChanged', {idToken: await firebase.auth().currentUser.getIdToken(), id: payload.id, cost: payload.costPerUnit});
});

export const requestGetAllTechnicians = createAsyncThunk('requestGetAllTechnicians', async function () {
  console.log('requestGetAllTechnicians');
  await socketClient.emit('getTechnicians', await firebase.auth().currentUser.getIdToken());
});

export const getTechnicians = createAsyncThunk(
  'getTechnicians',
  async function (_, { getState, dispatch }) {
    console.log('state ', getState());
    await socketClient.on('getTechnicians', (response) => {
      dispatch(slice.actions.getTechnicians(response));
    });
});

export const fetchCurrentInvoice = createAsyncThunk(
  'fetchCurrentInvoice',
  async function (_, { getState, dispatch }) {
    console.log('state ', getState());
    await socketClient.on('getInvoice', (response) => {
      console.log(response);
      dispatch({ type: 'invoices/fetchCurrentInvoice', payload: response });
    });
  });

export const fetchGetServices = createAsyncThunk(
  'fetchGetServices',
  async function (_, { getState, dispatch }) {
    console.log('state ', getState());
    await socketClient.on('getServices', (response) => {
      console.log(response);
      dispatch({ type: 'invoices/fetchGetServices', payload: response });
    });
  });

export const fetchWarranties = createAsyncThunk(
  'fetchWarranties',
  async function (_, { getState, dispatch }) {
    console.log('state ', getState());
    await socketClient.on('warrantiesFetched', (response) => {
      console.log(response);
      dispatch({ type: 'invoices/fetchWarranties', payload: response });
    });
  });

const slice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    getTechnicians(state, action) {
      console.log(action.payload);
      state.technicians = action.payload;
    },
    fetchCurrentInvoice(state, action) {
      console.log(action.payload);
      state.currentInvoice = action.payload;
    },
    fetchGetServices(state, action) {
      console.log(action.payload);
      state.services = action.payload;
    },
    fetchWarranties(state, action) {
      console.log(action.payload);
      state.warranties = action.payload;
    },
  }
});

export const reducer = slice.reducer;