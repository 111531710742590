import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firebase from 'src/utils/firebase';
import axios from 'axios';

const initialState = {
  techs: [],
  timeTable: {},
  prevTimeTable: {},
  lastClock: [],
  loading: false,
  error: null,
};

export const fetchPayrollData = createAsyncThunk('payroll/fetchPayrollData', async function (_, { dispatch }) {
  const idToken = await firebase.auth().currentUser.getIdToken();
  const clockResponse = await axios.get(`https://redline-autohaus-api.herokuapp.com/employees/hours/calculate/${idToken}`);
  const clockResponsePrevWeek = await axios.get(`https://redline-autohaus-api.herokuapp.com/employees/hours/prev/calculate/${idToken}`);

  dispatch(slice.actions.setTechs(clockResponse.data.employees));
  dispatch(slice.actions.setTimeTable(clockResponse.data.timeTable));
  dispatch(slice.actions.setPrevTimeTable(clockResponsePrevWeek.data.timeTable));
  dispatch(slice.actions.setLastClock(clockResponse.data.lastClock));
});

export const clockIn = createAsyncThunk('payroll/clockIn', async function (payload, { dispatch }) {
  const response = await axios.post("https://redline-autohaus-api.herokuapp.com/clock/in", {...payload, idToken: await firebase.auth().currentUser.getIdToken()});
  alert(response.data);
  dispatch(fetchPayrollData());
});

export const clockOut = createAsyncThunk('payroll/clockOut', async function (payload, { dispatch }) {
  const response = await axios.post("https://redline-autohaus-api.herokuapp.com/clock/out", {...payload, idToken: await firebase.auth().currentUser.getIdToken()});
  alert(response.data);
  dispatch(fetchPayrollData());
});

const slice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    setTechs(state, action) {
      state.techs = action.payload;
    },
    setTimeTable(state, action) {
      state.timeTable = action.payload;
    },
    setPrevTimeTable(state, action) {
      state.prevTimeTable = action.payload;
    },
    setLastClock(state, action) {
      state.lastClock = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayrollData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPayrollData.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchPayrollData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setTechs, setTimeTable, setPrevTimeTable, setLastClock, setLoading, setError } = slice.actions;
export const reducer = slice.reducer;