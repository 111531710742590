import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firebase from 'src/utils/firebase';
import { socketClient } from '../index';

const initialState = {
  stats: {},
  labourStats: {},
  techChartData: [],
  monthlyChartData: {},
  yearlyChartData: {},
  monthlyWebsiteChartData: {},
  visitsByCityData: {},
  channelVisits: {},
  pageVisits: [],
  aiResponse: '',
  loading: false,
  show: false,
};

export const requestGetStats = createAsyncThunk('requestGetStats', async () => {
  const idToken = await firebase.auth().currentUser.getIdToken();
  socketClient.emit('getStats', idToken);
});

export const requestGetYearlyChartData = createAsyncThunk('getYearlyChartData', async () => {
  const idToken = await firebase.auth().currentUser.getIdToken();
  socketClient.emit('getYearlyChartData', idToken);
});

export const fetchStats = createAsyncThunk(
  'fetchStats',
  async function (_, { dispatch }) {
    socketClient.on('getStats', (data) => {
      dispatch(setStats(data.stats));
      dispatch(setPageVisits(data.stats.googlePageTitleStats));
      dispatch(setVisitsByCityData(data.stats.googleVisitsByMonth));
      dispatch(setChannelVisits(data.stats.googleVisitsByChannelGroup));
      dispatch(setMonthlyWebsiteChartData(data.stats.websiteAnalytics));
      dispatch(setMonthlyChartData(data.stats.monthlyChartData));
      dispatch(setTechChartData(data.statsTech));
      dispatch(setLoading(true));
    });
  }
);

export const fetchAiAssistant = createAsyncThunk(
  'fetchAiAssistant',
  async function (_, { dispatch }) {
    socketClient.on('askAiAssistant', (data) => {
      dispatch(setAiResponse(data));
      dispatch(setShow(true));
    });
  }
);

export const fetchStreamDone = createAsyncThunk(
  'fetchStreamDone',
  async function (_, { dispatch }) {
    socketClient.on('streamDone', () => {
      dispatch(setAiResponse(''));
    });
  }
);

export const fetchYearlyChartData = createAsyncThunk(
  'fetchYearlyChartData',
  async function (_, { dispatch }) {
    socketClient.on('getYearlyChartData', (chartData) => {
      dispatch(setYearlyChartData(chartData));
      dispatch(setLabourStats(chartData.techLabour));
    });
  }
);

const slice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setStats(state, action) {
      state.stats = action.payload;
    },
    setLabourStats(state, action) {
      state.labourStats = action.payload;
    },
    setTechChartData(state, action) {
      state.techChartData = action.payload;
    },
    setMonthlyChartData(state, action) {
      state.monthlyChartData = action.payload;
    },
    setYearlyChartData(state, action) {
      state.yearlyChartData = action.payload;
    },
    setMonthlyWebsiteChartData(state, action) {
      state.monthlyWebsiteChartData = action.payload;
    },
    setVisitsByCityData(state, action) {
      state.visitsByCityData = action.payload;
    },
    setChannelVisits(state, action) {
      state.channelVisits = action.payload;
    },
    setPageVisits(state, action) {
      state.pageVisits = action.payload;
    },
    setAiResponse(state, action) {
      state.aiResponse = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setShow(state, action) {
      state.show = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestGetStats.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestGetStats.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(requestGetStats.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchStats.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStats.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchStats.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchAiAssistant.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAiAssistant.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchAiAssistant.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchStreamDone.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStreamDone.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchStreamDone.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchYearlyChartData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchYearlyChartData.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchYearlyChartData.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setStats,
  setLabourStats,
  setTechChartData,
  setMonthlyChartData,
  setYearlyChartData,
  setMonthlyWebsiteChartData,
  setVisitsByCityData,
  setChannelVisits,
  setPageVisits,
  setAiResponse,
  setLoading,
  setShow,
} = slice.actions;

export const reducer = slice.reducer;