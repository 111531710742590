import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firebase from 'src/utils/firebase';
import {socketClient}  from '../index';


const initialState = {
  users: [],
  singleUser: {},
};

export const requestGetAllUsers = createAsyncThunk('requestGetAllUsers', async function () {
  console.log('requestGetAllUsers');
  await socketClient.emit('getCustomers', await firebase.auth().currentUser.getIdToken());
});

export const getSingleUser = createAsyncThunk('getSingleUser', async function (customerId) {
  console.log('getSingleUser');
  console.log(customerId);
  await socketClient.emit('updateCustomers', {idToken: await firebase.auth().currentUser.getIdToken(), customerId});
});

export const searchCustomer = createAsyncThunk('searchCustomer', async function (search) {
  await socketClient.emit('searchCustomer', {idToken: await firebase.auth().currentUser.getIdToken(), search});
});

export const createCustomer = createAsyncThunk('createCustomer', async function (data) {
  await socketClient.emit('createCustomer', {idToken: await firebase.auth().currentUser.getIdToken(), ...data});
});

export const createVehicle = createAsyncThunk('createVehicle', async function (data) {
  await socketClient.emit('addVehicle', {...data , idToken: await firebase.auth().currentUser.getIdToken()});
});

export const createNewQuote = createAsyncThunk('createNewQuote', async function (data) {
  await socketClient.emit('newQuote', {...data , idToken: await firebase.auth().currentUser.getIdToken()});
});

export const fetchNewQuote = createAsyncThunk('fetchNewQuote', async function () {
  return new Promise((resolve) => {
    socketClient.on('newQuote', (response) => {
      console.log(response);
      resolve(response);
    });
  });
});
  
export const fetchAllUsers = createAsyncThunk(
  'fetchAllUsers',
  async function (_, { getState, dispatch }) {
    console.log('state ', getState());
    await socketClient.on('getCustomers', (response) => {
      console.log(response);
      dispatch({ type: 'users/fetchAllUsers', payload: response });
    });
  });

export const fetchSingleUser = createAsyncThunk(
  'fetchSingleUser',
  async function (_, { getState, dispatch }) {
    console.log('state ', getState());
    await socketClient.on('updateCustomers', (response) => {
      console.log(response);
      dispatch({ type: 'users/fetchSingleUser', payload: response });
    });
  });

export const fetchSearchUser = createAsyncThunk(
  'fetchSearchUser',
  async function (_, { getState, dispatch }) {
    console.log('state ', getState());
    await socketClient.on('searchCustomer', (response) => {
      console.log(response);
      dispatch({ type: 'users/fetchAllUsers', payload: response });
    });
  });


export const fetchCreateCustomer = createAsyncThunk(
  'fetchCreateCustomer',
  async function (data, { getState, dispatch }) {
    console.log('state ', getState());
    return new Promise(async (resolve) => {
      socketClient.emit('createCustomer', { idToken: await firebase.auth().currentUser.getIdToken(), ...data });
      socketClient.on('createCustomer', (response) => {
        console.log('createCustomer');
        console.log(response);
        dispatch({ type: 'users/fetchSingleUser', payload: response });
        resolve(response);
      });
    });
  }
);

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchAllUsers(state, action) {
      console.log(action.payload);
      state.users = action.payload;
    },
    fetchSingleUser(state, action) {
      console.log(action.payload);
      state.singleUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestGetAllUsers.pending, (state) => {
      state.requestGetAllUsersStatus = 'Pending';
    });
    builder.addCase(requestGetAllUsers.fulfilled, (state) => {
      state.requestGetAllUsersStatus = 'Sent';
    });
    builder.addCase(requestGetAllUsers.rejected, (state) => {
      state.requestGetAllUsersStatus = 'Rejected';
    });
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.fetchAllUsersStatus = 'Payload Pending';
    });
    builder.addCase(fetchAllUsers.fulfilled, (state) => {
      state.fetchAllUsersStatus = 'Payload Fulfilled';
    });
    builder.addCase(fetchAllUsers.rejected, (state) => {
      state.fetchAllUsersStatus = 'Payload Error';
    });
  },
});

export const reducer = slice.reducer;