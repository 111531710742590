export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: "AIzaSyCpdytcPVswNIial11wcbXa0FvnSRceSnM",
  authDomain: "watchdog-3a67c.firebaseapp.com",
  databaseURL: "https://watchdog-3a67c.firebaseio.com",
  projectId: "watchdog-3a67c",
  storageBucket: "watchdog-3a67c.appspot.com",
  messagingSenderId: "638923428670",
  appId: "1:638923428670:web:710bc04d41504503ee126f",
  measurementId: "G-7BX1K4BGWB"
};

export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};
